<template>
  <div>
    <div class="public-margin flex-right">
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="mini"
        @click="addReservationClass"
        >新增预约分类</el-button
      >
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      max-height="calc(100vh - 240px)"
      stripe
    >
      <el-table-column prop="WechatNo" label="预约分类编号"> </el-table-column>
      <el-table-column prop="WechatName" label="预约分类名称">
      </el-table-column>
      <el-table-column label="总部">
        <template slot-scope="scope">
          <div>{{ scope.row.HeadMark | whether }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="onEditReservationClass(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="onDeleteReservationClass(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <EditDialog
      :formData="formData"
      :etitle="etitle"
      :dialogTableVisible="showDialog"
      :callFatherFunction="callFatherFunction"
      @closeDialog="onCloseDialog"
    >
    </EditDialog>
  </div>
</template>

<script>
import service from "../../../../api/service.js";
import EditDialog from "./components/edit-dialog.vue";
export default {
  name: "StoreWebCategory",

  components: { EditDialog },

  directives: {},

  data() {
    return {
      tableData: [],
      formData: {},
      etitle: "",
      showDialog: false,
    };
  },

  mounted() {
    this.initPageData();
  },

  filters: {
    whether(param) {
      return param == 0 ? "否" : "是";
    },
  },

  methods: {
    async initPageData() {
      try {
        let { data } = await service.getItemwechat();
        this.tableData = data;
      } catch (e) {
        console.error(e);
      }
    },

    addReservationClass() {
      this.etitle = "添加预约分类";
      this.formData = {
        WechatName: "",
      };
      this.showDialog = true;
    },

    onEditReservationClass(event) {
      this.etitle = "修改预约分类";
      this.formData = event;
      this.showDialog = true;
    },

    onDeleteReservationClass(event) {
      this.$confirm(`是否删除该‘${event.WechatName}’`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            let { data, errcode, errmsg } = await service.deleteItemwechat({
              wechat_guid: event.WechatGuid,
            });
            if (errcode == 0) {
              this.$message({
                message: "删除成功!",
                type: "success",
              });
              this.initPageData();
            } else {
              this.$message.error(errmsg);
            }
          } catch (e) {
            console.error(e);
          }
        })
        .catch(() => {});
    },

    onCloseDialog() {
      this.initPageData();
    },

    callFatherFunction() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
</style>