<template>
  <div>
    <el-dialog
      :title="etitle"
      :visible.sync="dialogTableVisible"
      width="400px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog('formRules')"
    >
      <el-form
        ref="formRules"
        :model="formData"
        :rules="formRules"
        label-width="110px"
      >
        <el-form-item label="预约分类名称" prop="WechatName">
          <el-input
            v-model="formData.WechatName"
            placeholder="请输入分类名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCloseDialog('formRules')">取 消</el-button>
        <el-button type="primary" @click="submitForm('formRules')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import service from "../../../../../api/service.js";
export default {
  props: {
    etitle: {
      type: String,
      default: "添加",
    },

    dialogTableVisible: {
      type: Boolean,
      default: false,
    },

    callFatherFunction: {
      type: Function,
    },

    formData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      formRules: {
        WechatName: [
          { required: true, message: "请输入级别名称", trigger: "blur" },
          { min: 2, max: 8, message: "长度在 2 到 8 个字符", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    //
    onCloseDialog(formName) {
      this.callFatherFunction();
      this.$refs[formName].resetFields();
      this.$emit("closeDialog");
    },

    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            let submitData = {
              WechatName: this.formData.WechatName,
            };
            // 如果有 WechatGuid 就是修改
            if (this.formData.WechatGuid) {
              submitData.WechatGuid = this.formData.WechatGuid;
              let { data, errcode, errmsg } = await service.updateItemwechat(
                submitData
              );
              if (errcode == 0) {
                this.onCloseDialog("formRules");
                this.$message({
                  message: "修改成功!",
                  type: "success",
                });
              } else {
                this.$message.error(errmsg);
              }
            } else {
              let { data, errcode, errmsg } = await service.addItemwechat(
                submitData
              );
              if (errcode == 0) {
                this.onCloseDialog("formRules");
                this.$message({
                  message: "添加成功!",
                  type: "success",
                });
              } else {
                this.$message.error(errmsg);
              }
            }
          } catch (e) {
            console.log(e);
            //TODO handle the exception
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-select {
  width: 100%;
}
</style>